import useAlertAdd from "@@/composables/alert/useAlertAdd.js";

export default () => {
	const setBackendValidation = (action, result) => {
		if (!action || !result) {
			return false;
		}

		const { addErrorAlert } = useAlertAdd();

		if (result.response.data.errors) {
			Object.keys(result.response.data.errors).forEach((key) => {
				const messages = result.response.data.errors[key];
				action(key, messages.join(" "));

				addErrorAlert(messages.join(" "));
			});
		}
	};

	return { setBackendValidation };
};
