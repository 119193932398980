import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { mdi } from "vuetify/iconsets/mdi";
import { ms } from "@/vuetify/iconsets/material_symbols";

export const vuetifySettings = {
	components,
	directives,
	icons: {
		defaultSet: "mdi",
		sets: {
			mdi,
			ms,
		},
	},
	theme: {
		defaultTheme: "stc",
		themes: {
			stc: {
				dark: false,
				colors: {
					background: "#FFFFFF",
					surface: "#FFFFFF",
					transparent: "#FFFFFF",

					black: "#000000",
					white: "#FFFFFF",
					colorGrey900: "#171717",
					colorGrey800: "#303030",
					colorGrey700: "#585959",
					colorGrey600: "#8c8d8d",
					colorGrey500: "#a6a6a6",
					colorGrey400: "#E0E0E0",
					colorGrey300: "#E9E6E4",
					colorGrey200: "#ededed",
					colorGrey100: "#f6f6f6",

					colorYellow600: "#A85A00",
					colorYellow500: "#FFAF54",
					colorYellow400: "#FFE5C7",

					colorGreen500: "#18AE08",
					colorGreen300: "#ADDB67",
					colorGreen200: "#D5F0AD",
					colorGreen100: "#ADDB67",

					colorOrange500: "#F65F21",

					colorPink500: "#EDC6C6",
					colorPink400: "#F7DADA",

					colorBlue500: "#A7D1DB",
					colorBlue400: "#C2E5ED",

					colorTurquoise600: "#23806C",
					colorTurquoise500: "#5ABFA9",
					colorTurquoise400: "#D7FAF2",

					colorPurple400: "#EDF1FF",

					primary: "#ffed00",
					secondary: "#171717",
					tertiary: "#FFFFFF",

					error: "#F65F21",
					info: "#4d63e8",
					success: "#18AE08",
					warning: "#F57F17",

					progressColor: "#585959",
					progressBgColor: "#585959",
				},
				variables: {
					"input-padding-top": "12px",
					"field-padding-bottom": "8px !important",
				},
			},
		},
	},
	ssr: !!import.meta.env.SSR,
};

export default vuetifySettings;
