export default () => {
	const idS4 = () => {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	};

	const guid = () => {
		return `${idS4()}${idS4()}-${idS4()}-${idS4()}-${idS4()}-${idS4()}${idS4()}`;
	};

	return { idS4, guid };
};
