import RouteExistsMiddleware from "@@/middlewares/RouteExistsMiddleware";
import LocaleExistsMiddleware from "@@/middlewares/LocaleExistsMiddleware";
import errorRoutes from "@/routes/error";

const routes = [
	{
		path: "",
		redirect: {
			name: "main",
			params: {
				locale: "pl",
			},
		},
	},
	{
		name: "main",
		path: `/:locale`,
		component: () => import("@/views/Index.vue"),
		meta: {
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "home",
		},
		children: [
			{
				name: "home",
				path: "",
				component: () => import("@/views/pages/home/Home.vue"),
				meta: {
					layout: "default",
				},
			},
			{
				name: "survey",
				path: "survey/:id?",
				component: () => import("@/views/pages/survey/Survey.vue"),
				meta: {
					layout: "default",
				},
			},
			{
				name: "demo",
				path: "demo",
				component: () => import("@/views/pages/demo/Demo.vue"),
				meta: {
					layout: "default",
				},
			},
			{
				name: "errors",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "error",
				},
				children: [...errorRoutes],
			},
		],
	},
	{
		path: "/:path(.*)*",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [LocaleExistsMiddleware, RouteExistsMiddleware],
		},
	},
];

export default routes;
