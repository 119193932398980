import { SurveyTemplateEnum } from "@/enums/SurveyTemplateEnum.js";
import { SurveyTypeEnum } from "@/enums/SurveyTypeEnum.js";
import { AgreementSubtypeEnum } from "@/enums/AgreementSubtypeEnum.js";

export const survey = {
	surveyById: [
		{
			id: 1,
			type: SurveyTypeEnum.FEEDBACK,
			template: SurveyTemplateEnum.FEEDBACK_1,
		},
		{
			id: 2,
			type: SurveyTypeEnum.INTERVIEW,
			template: SurveyTemplateEnum.INTERVIEW_1,
			agreements: AgreementSubtypeEnum.SURVEY_INTERVIEW,
		},
	],
};
